import { verifyJWT as coreVerifyJWT } from '@paniclobster/commons'

import type { VerifyJWTOptions, VerifyJWTResult } from '@paniclobster/commons'

import { getJWTConfigs, getJWTPublicKey } from '../core/configs'

export function verifyJWT(
  jwt: string,
  options: Partial<VerifyJWTOptions> = {},
): Promise<VerifyJWTResult | undefined> {
  const jwtConfigs = getJWTConfigs()

  const jwtPublicKey = getJWTPublicKey()

  const {
    algorithm = jwtConfigs.algorithm,
    audience = jwtConfigs.audience,
    issuer = jwtConfigs.issuer,
    publicKey = jwtPublicKey,
    publicKeyFormat = 'JWK',
  } = options

  return coreVerifyJWT(jwt, {
    algorithm,
    audience,
    issuer,
    publicKey,
    publicKeyFormat,
  })
}
