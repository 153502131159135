import axios from 'axios'

import type {
  NullableContract,
  SkillContract,
  TagContract,
} from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetTagsResponse = NullableContract<TagContract[]>

export async function getTags(
  options: BaseRequestOptions = {},
): Promise<GetTagsResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl('/data/tags')

  const request = axios
    .get<GetTagsResponse>(requestUri, { signal: abortSignal })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetSkillsResponse = NullableContract<SkillContract[]>

export async function getSkills(
  options: BaseRequestOptions = {},
): Promise<GetSkillsResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl('/data/skills')

  const request = axios
    .get<GetSkillsResponse>(requestUri, { signal: abortSignal })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
