import { InvalidConfigError, parseJsonConfig } from '@paniclobster/commons'

function getOAuthConfigsData() {
  return (process.env.OAUTH_CONFIGS ??
    process.env.NEXT_PUBLIC_OAUTH_CONFIGS) as string
}

export interface OAuthConfigs {
  flowValidity: number
  providers: {
    providerName: string
  }[]
}

export function getOAuthConfigs(oAuthConfigsData: string) {
  return parseJsonConfig<OAuthConfigs>(oAuthConfigsData)
}

export interface GetOAuthProvidersNamesOptions {
  oAuthConfigsData?: string
}

export function getOAuthProvidersNames(
  options: GetOAuthProvidersNamesOptions = {},
): string[] {
  const { oAuthConfigsData = getOAuthConfigsData() } = options

  const configs = getOAuthConfigs(oAuthConfigsData)

  if (!configs) {
    throw new InvalidConfigError(oAuthConfigsData)
  }

  const { providers } = configs

  return providers.map((provider) => provider.providerName)
}
