import axios from 'axios'

import type { NullableContract, ProfileContract } from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetSessionProfileResponse = NullableContract<ProfileContract>

export async function getSessionProfile(
  options: BaseRequestOptions = {},
): Promise<GetSessionProfileResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl('/me')

  const request = axios
    .get<GetSessionProfileResponse>(requestUri, { signal: abortSignal })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
