import { createContext, useContext } from 'react'

import type { Context } from 'react'

export interface Authentication {
  sub?: string
}

export const authenticationContext: Context<Authentication | undefined> =
  createContext<Authentication | undefined>(undefined)

export default function useAuthenticationContext() {
  return useContext(authenticationContext)
}
