import axios from 'axios'

import type { MediaContract, NullableContract } from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetMediaByMediaUUIDResponse = NullableContract<MediaContract>

export async function getMediaByMediaUUID(
  mediaUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetMediaByMediaUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/presentations/medias/by-uuid/${mediaUUID}`)

  const request = axios
    .get<GetMediaByMediaUUIDResponse>(requestUri, { signal: abortSignal })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
