import axios from 'axios'

import type { NullableContract, ProfileContract } from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetProfileByProfileIdResponse = NullableContract<ProfileContract>

export async function getProfileByProfileId(
  workspaceId: string,
  options: BaseRequestOptions = {},
): Promise<GetProfileByProfileIdResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/profiles/${workspaceId}`)

  const request = axios
    .get<GetProfileByProfileIdResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
