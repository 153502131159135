import axios from 'axios'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetCredentialsFlowResponse = {
  data?: Record<string, string>
  errors?: Record<string, string>
  operation?: string
} | null

export async function getCredentialsFlow(
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetCredentialsFlowResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl('/credentials/flow')

  const request = axios
    .get(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))
    .catch(() => null)

  return request
}
