import axios from 'axios'

import type {
  NullableContract,
  TeamContract,
  WorkflowContract,
} from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetWorkflowByWorkflowUUIDResponse =
  NullableContract<WorkflowContract>

export async function getWorkflowByWorkflowUUID(
  workflowUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetWorkflowByWorkflowUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workflows/by-uuid/${workflowUUID}`)

  const request = axios
    .get<GetWorkflowByWorkflowUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetTeamByIdAndWorkspaceUUIDResponse = NullableContract<TeamContract>

export async function getTeamByIdAndWorkspaceUUID(
  teamId: number,
  workspaceUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetTeamByIdAndWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workflows/by-workspace-uuid/${workspaceUUID}/teams/${teamId}`,
  )

  const request = axios
    .get<GetTeamByIdAndWorkspaceUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
