/* eslint-disable @next/next/no-img-element */

import type { StaticImageData } from 'next/image'

import combinationMarkOnDarkImage from './assets/combination-mark-on-dark.png'
import combinationMarkOnLightImage from './assets/combination-mark-on-light.png'

import logoMarkImage from './assets/logo-mark.png'

type LogoTypes = 'combination' | 'mark'

export interface LogoProps {
  className?: string
  type?: LogoTypes
}

export default function Logo(props: LogoProps) {
  const { className, type = 'combination' } = props

  let logoOnDarkImage: StaticImageData
  let logoOnLightImage: StaticImageData

  switch (type) {
    case 'combination': {
      logoOnDarkImage = combinationMarkOnDarkImage
      logoOnLightImage = combinationMarkOnLightImage
      break
    }
    case 'mark': {
      logoOnDarkImage = logoMarkImage
      logoOnLightImage = logoMarkImage
      break
    }
    default: {
      logoOnDarkImage = combinationMarkOnDarkImage
      logoOnLightImage = combinationMarkOnLightImage
      break
    }
  }

  return (
    <picture>
      {logoOnDarkImage !== logoOnLightImage && (
        <source
          srcSet={logoOnDarkImage.src}
          media="(prefers-color-scheme: dark)"
        />
      )}

      <img
        className={className}
        alt="Panic Lobster Logo"
        src={logoOnLightImage.src}
      />
    </picture>
  )
}
