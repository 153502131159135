import axios from 'axios'

import type {
  NullableContract,
  PaginatedContract,
  ProfileWorkspacesSummaryContract,
  TeamContract,
  TeamInviteContract,
  TeamMemberContract,
  TeamVacancyCandidateContract,
  WorkspaceContract,
  WorkspaceInviteContract,
  WorkspaceSummaryContract,
  WorkspaceUserContract,
} from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetWorkspaceByWorkspaceSlugResponse =
  NullableContract<WorkspaceContract>

export async function getWorkspaceByWorkspaceSlug(
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetWorkspaceByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-slug/${workspaceSlug}`)

  const request = axios
    .get<GetWorkspaceByWorkspaceSlugResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetSessionWorkspaceUserByWorkspaceSlugResponse =
  NullableContract<WorkspaceUserContract>

export async function getSessionWorkspaceUserByWorkspaceSlug(
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetSessionWorkspaceUserByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-slug/${workspaceSlug}/user`)

  const request = axios
    .get<GetSessionWorkspaceUserByWorkspaceSlugResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetSessionTeamByWorkspaceSlugResponse =
  NullableContract<TeamContract>

export async function getSessionTeamByWorkspaceSlug(
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetSessionTeamByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-slug/${workspaceSlug}/team`)

  const request = axios
    .get<GetSessionTeamByWorkspaceSlugResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetSessionTeamMembershipByWorkspaceSlugResponse =
  NullableContract<TeamMemberContract>

export async function getSessionTeamMembershipByWorkspaceSlug(
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetSessionTeamMembershipByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/team/membership`,
  )

  const request = axios
    .get<GetSessionTeamMembershipByWorkspaceSlugResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetWorkspaceInviteByWorkspaceInviteUUIDAndWorkspaceSlugResponse =
  NullableContract<WorkspaceInviteContract>

export async function getWorkspaceInviteByWorkspaceInviteUUIDAndWorkspaceSlug(
  workspaceInviteUUID: string,
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetWorkspaceInviteByWorkspaceInviteUUIDAndWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/invites/by-uuid/${workspaceInviteUUID}`,
  )

  const request = axios
    .get<GetWorkspaceInviteByWorkspaceInviteUUIDAndWorkspaceSlugResponse>(
      requestUri,
      { signal: abortSignal },
    )
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetWorkspaceInvitesByWorkspaceSlugResponse = NullableContract<
  WorkspaceInviteContract[]
>

export async function getWorkspaceInvitesByWorkspaceSlug(
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetWorkspaceInvitesByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-slug/${workspaceSlug}/invites`)

  const request = axios
    .get<GetWorkspaceInvitesByWorkspaceSlugResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetWorkspaceSummaryByWorkspaceUUIDResponse =
  NullableContract<WorkspaceSummaryContract>

export async function getWorkspaceSummaryByWorkspaceUUID(
  workspaceUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetWorkspaceSummaryByWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-uuid/${workspaceUUID}/summary`)

  const request = axios
    .get<GetWorkspaceSummaryByWorkspaceUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetTeamsByWorkspaceSlugResponse = NullableContract<
  PaginatedContract<TeamContract>
>

export async function getTeamsByWorkspaceSlug(
  workspaceSlug: string,
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetTeamsByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-slug/${workspaceSlug}/teams`)

  const request = axios
    .get<GetTeamsByWorkspaceSlugResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetWorkspaceTeamsInvitesByWorkspaceSlugResponse = NullableContract<
  PaginatedContract<TeamInviteContract>
>

export async function getWorkspaceTeamsInvitesByWorkspaceSlug(
  workspaceSlug: string,
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetWorkspaceTeamsInvitesByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/teams-invites`,
  )

  const request = axios
    .get<GetWorkspaceTeamsInvitesByWorkspaceSlugResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetTeamByTeamIdAndWorkspaceSlugResponse =
  NullableContract<TeamContract>

export async function getTeamByTeamIdAndWorkspaceSlug(
  teamId: number,
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetTeamByTeamIdAndWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/teams/${teamId}`,
  )

  const request = axios
    .get<GetTeamByTeamIdAndWorkspaceSlugResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetTeamVacancyCandidatesByTeamVacancyIdAndWorkspaceSlugResponse =
  NullableContract<TeamVacancyCandidateContract[]>

export async function getTeamVacancyCandidatesByTeamVacancyIdAndWorkspaceSlug(
  teamVacancyId: number,
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetTeamVacancyCandidatesByTeamVacancyIdAndWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/team/vacancies/${teamVacancyId}/candidates`,
  )

  const request = axios
    .get<GetTeamVacancyCandidatesByTeamVacancyIdAndWorkspaceSlugResponse>(
      requestUri,
      {
        signal: abortSignal,
      },
    )
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetTeamMembersByTeamIdAndWorkspaceSlugResponse = NullableContract<
  TeamMemberContract[]
>

export async function getTeamMembersByTeamIdAndWorkspaceSlug(
  teamId: number,
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<GetTeamMembersByTeamIdAndWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/teams/${teamId}/members`,
  )

  const request = axios
    .get<GetTeamMembersByTeamIdAndWorkspaceSlugResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type PostTeamInviteAcceptanceByTeamInviteIdAndWorkspaceSlugResponse =
  NullableContract<TeamVacancyCandidateContract[]>

export async function postTeamInviteAcceptanceByTeamInviteIdAndWorkspaceSlug(
  teamInviteId: number,
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<PostTeamInviteAcceptanceByTeamInviteIdAndWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/teams-invites/${teamInviteId}/accept`,
  )

  const request = axios
    .post<PostTeamInviteAcceptanceByTeamInviteIdAndWorkspaceSlugResponse>(
      requestUri,
      { signal: abortSignal },
    )
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type PostTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlugResponse =
  NullableContract<TeamVacancyCandidateContract[]>

export async function postTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlug(
  teamVacancyId: number,
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<PostTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/teams-vacancies/${teamVacancyId}/candidate`,
  )

  const request = axios
    .post<PostTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlugResponse>(
      requestUri,
      { signal: abortSignal },
    )
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type DeleteTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlugResponse =
  NullableContract<TeamVacancyCandidateContract[]>

export async function deleteTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlug(
  teamVacancyId: number,
  workspaceSlug: string,
  options: BaseRequestOptions = {},
): Promise<DeleteTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/teams-vacancies/${teamVacancyId}/candidate`,
  )

  const request = axios
    .delete<DeleteTeamVacancyCandidateByTeamVacancyIdAndWorkspaceSlugResponse>(
      requestUri,
      { signal: abortSignal },
    )
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetWorkspacesSummaryByUserUUIDResponse =
  NullableContract<ProfileWorkspacesSummaryContract>

export async function getWorkspacesSummaryByUserUUID(
  userUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetWorkspacesSummaryByUserUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-user-uuid/${userUUID}/summary`)

  const request = axios
    .get<GetWorkspacesSummaryByUserUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetInvitableParticipantsByWorkspaceSlugResponse = NullableContract<
  WorkspaceUserContract[]
>

export async function getInvitableParticipantsByWorkspaceSlug(
  workspaceSlug: string,
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetInvitableParticipantsByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/workspaces/by-slug/${workspaceSlug}/users/invitable`,
  )

  const request = axios
    .get<GetInvitableParticipantsByWorkspaceSlugResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetWorkspaceUsersByWorkspaceSlugResponse = NullableContract<
  PaginatedContract<WorkspaceUserContract>
>

export async function getWorkspaceUsersByWorkspaceSlug(
  workspaceSlug: string,
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetWorkspaceUsersByWorkspaceSlugResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(`/workspaces/by-slug/${workspaceSlug}/users`)

  const request = axios
    .get<GetWorkspaceUsersByWorkspaceSlugResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
