import { InvalidConfigError, parseJsonConfig } from '@paniclobster/commons'

import type { JWTAlgorithm } from '@paniclobster/commons'

export interface GetJWTConfigsOptions {
  jwtConfigsData?: string
}

export interface JWTConfigs {
  algorithm: JWTAlgorithm
  audience: string
  expiresAfter: string
  issuer: string
}

export function getJWTConfigs(options: GetJWTConfigsOptions = {}): JWTConfigs {
  const {
    jwtConfigsData = (process.env.JWT_CONFIGS ??
      process.env.NEXT_PUBLIC_JWT_CONFIGS) as string,
  } = options

  const jwtConfigs = parseJsonConfig<JWTConfigs>(jwtConfigsData)

  if (!jwtConfigs) {
    throw new InvalidConfigError(jwtConfigsData)
  }

  return jwtConfigs
}

function getJWTKey(keyData: string): string | undefined {
  try {
    const jwtKeyData = parseJsonConfig<object>(keyData)

    return JSON.stringify(jwtKeyData)
  } catch {
    return keyData
  }
}

export interface GetJWTPublicKeyOptions {
  publicKeyData?: string
}

export function getJWTPublicKey(options: GetJWTPublicKeyOptions = {}): string {
  const {
    publicKeyData = (process.env.JWT_PUBLIC_KEY ??
      process.env.NEXT_PUBLIC_JWT_PUBLIC_KEY) as string,
  } = options

  const jwtPublicKey = getJWTKey(publicKeyData)

  if (!jwtPublicKey) {
    throw new InvalidConfigError(publicKeyData)
  }

  return jwtPublicKey
}
