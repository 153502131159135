import axios from 'axios'

import type {
  NotificationContract,
  NullableContract,
  PaginatedContract,
} from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetSessionNotificationsResponse = NullableContract<
  PaginatedContract<NotificationContract>
>

export async function getSessionNotifications(
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetSessionNotificationsResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl('/notifications')

  const request = axios
    .get<GetSessionNotificationsResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type PostReadNotificationByNotificationUUIDResponse =
  NullableContract<NotificationContract>

export async function postReadNotificationByNotificationUUID(
  notificationUUID: string,
  options: BaseRequestOptions = {},
) {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/notifications/by-notification-uuid/${notificationUUID}/read`,
  )

  const request = axios
    .post<PostReadNotificationByNotificationUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
