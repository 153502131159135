import NotFoundError from '../errors/NotFoundError'
import UnauthorizedError from '../errors/UnauthorizedError'

export interface ErrorHandlerOptions {
  throwOnNotFound?: boolean
  throwOnUnauthorized?: boolean
  throwOnUncaught?: boolean
}

export function errorHandler(options: ErrorHandlerOptions = {}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (err: any) => {
    const {
      throwOnNotFound = false,
      throwOnUnauthorized = true,
      throwOnUncaught = true,
    } = options

    switch (err?.response?.status) {
      case 401: {
        if (throwOnUnauthorized) {
          throw new UnauthorizedError()
        }

        return null
      }
      case 404: {
        if (throwOnNotFound) {
          throw new NotFoundError()
        }

        return null
      }
      default: {
        if (throwOnUncaught) {
          throw err
        }

        return null
      }
    }
  }
}

export interface BaseRequestOptions extends ErrorHandlerOptions {
  abortSignal?: AbortSignal
}
