import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'

import { verifyJWT } from '../helpers'

import type { Authentication } from './useAuthenticationContext'

export interface UseAuthenticationState {
  authentication?: Authentication
  isAuthenticating: boolean
}

export default function useAuthentication(): UseAuthenticationState {
  const sessionToken = Cookies.get('sessionToken')

  const [isAuthenticating, setIsAuthenticating] = useState(true)

  const [authentication, setAuthentication] = useState<
    Authentication | undefined
  >()

  const authenticate = useCallback(async () => {
    if (sessionToken) {
      const jwtVerification = await verifyJWT(sessionToken)

      setAuthentication(jwtVerification?.payload)
    }

    setIsAuthenticating(false)
  }, [sessionToken])

  useEffect(() => {
    authenticate()
  }, [authenticate])

  return {
    isAuthenticating,
    authentication,
  }
}
