export interface ParsedWorkspaceDataValue {
  content: string
  mimeType: string
}

export function parseWorkspaceDataValue(
  value?: string,
): ParsedWorkspaceDataValue | undefined {
  if (typeof value === 'undefined') {
    return value
  }

  if (!value.startsWith('data:')) {
    return {
      content: value,
      mimeType: 'text/plain',
    }
  }

  const dataValue = value.replace('data:', '')

  const [mediaTypeAndEncoding, encodedContent] = dataValue.split(',')
  const [mimeType, encoding] = mediaTypeAndEncoding.split(';')

  let content: string

  switch (encoding) {
    case 'base64': {
      content = window.atob(encodedContent)
      break
    }
    default: {
      content = encodedContent
      break
    }
  }

  return {
    content,
    mimeType,
  }
}
