import { InvalidConfigError, parseJsonConfig } from '@paniclobster/commons'

function getApiConfigsData() {
  return (process.env.API_CONFIGS ??
    process.env.NEXT_PUBLIC_API_CONFIGS) as string
}

export interface ApiConfigs {
  baseUrl?: string
  version?: string
}

export function getApiConfigs(apiConfigsData: string) {
  return parseJsonConfig<ApiConfigs>(apiConfigsData)
}

export interface GetApiUrlOptions {
  apiConfigsData?: string
}

export function getApiUrl(
  apiUrlPath = '',
  options: GetApiUrlOptions = {},
): string {
  const { apiConfigsData = getApiConfigsData() } = options

  const configs = getApiConfigs(apiConfigsData)

  if (!configs) {
    throw new InvalidConfigError(apiConfigsData)
  }

  const { baseUrl, version } = configs

  return `${baseUrl}/${version}${apiUrlPath}`
}
