import { useEffect } from 'react'
import { useRouter } from 'next/router'

import type { ReactNode } from 'react'

import useAuthentication from '../../hooks/useAuthentication'
import { authenticationContext } from '../../hooks/useAuthenticationContext'

export interface AuthenticationProviderProps {
  authenticationRequired?: boolean
  children: ReactNode
}

export default function AuthenticationProvider(
  props: AuthenticationProviderProps,
) {
  const router = useRouter()

  const { authenticationRequired = false, children } = props

  const { authentication, isAuthenticating } = useAuthentication()

  useEffect(() => {
    if (authenticationRequired && !isAuthenticating && !authentication) {
      const urlSearchParams = new URLSearchParams()
      urlSearchParams.append('return-to-uri', router.asPath)

      router.replace(`/signin?${urlSearchParams.toString()}`)
    }
  }, [authentication, authenticationRequired, isAuthenticating, router])

  if (isAuthenticating || (authenticationRequired && !authentication)) {
    return null
  }

  return (
    <authenticationContext.Provider value={authentication}>
      {children}
    </authenticationContext.Provider>
  )
}
