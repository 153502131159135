import { InvalidConfigError, parseJsonConfig } from '@paniclobster/commons'

function getWorkspacesConfigsData() {
  return (process.env.WORKSPACES_CONFIGS ??
    process.env.NEXT_PUBLIC_WORKSPACES_CONFIGS) as string
}

export interface WorkspacesConfigs {
  templates: {
    [slug: string]: {
      uuid: string
    }
  }
  workspaces: {
    [slug: string]: {
      uuid: string
      workflows: {
        [slug: string]: {
          uuid: string
        }
      }
    }
  }
}

export function getWorkspacesConfigs(workspacesConfigsData: string) {
  return parseJsonConfig<WorkspacesConfigs>(workspacesConfigsData)
}

export interface GetTemplatesOptions {
  workspacesConfigsData?: string
}

export function getTemplates(
  options: GetTemplatesOptions = {},
): WorkspacesConfigs['templates'] {
  const { workspacesConfigsData = getWorkspacesConfigsData() } = options

  const configs = getWorkspacesConfigs(workspacesConfigsData)

  if (!configs) {
    throw new InvalidConfigError(workspacesConfigsData)
  }

  const { templates } = configs

  return templates
}

export interface GetWorkspacesOptions {
  workspacesConfigsData?: string
}

export function getWorkspaces(
  options: GetWorkspacesOptions = {},
): WorkspacesConfigs['workspaces'] {
  const { workspacesConfigsData = getWorkspacesConfigsData() } = options

  const configs = getWorkspacesConfigs(workspacesConfigsData)

  if (!configs) {
    throw new InvalidConfigError(workspacesConfigsData)
  }

  const { workspaces } = configs

  return workspaces
}
