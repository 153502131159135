import axios from 'axios'

import type {
  EvaluatorContract,
  NullableContract,
  PaginatedContract,
  SettingsContract,
  TeamContract,
} from '@paniclobster/commons'

import { getApiUrl } from '../core/configs'

import { errorHandler } from './base'

import type { BaseRequestOptions } from './base'

export type GetAdministrationEvaluatableTeamsByWorkspaceUUIDResponse =
  NullableContract<PaginatedContract<TeamContract>>

export async function getAdministrationEvaluatableTeamsByWorkspaceUUID(
  workspaceUUID: string,
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetAdministrationEvaluatableTeamsByWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/evaluations/by-workspace-uuid/${workspaceUUID}/administration/teams/evaluatable`,
  )

  const request = axios
    .get<GetAdministrationEvaluatableTeamsByWorkspaceUUIDResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetAdministrationRankingByWorkspaceUUIDResponse = NullableContract<
  TeamContract[]
>

export async function getAdministrationRankingByWorkspaceUUID(
  workspaceUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetAdministrationRankingByWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/evaluations/by-workspace-uuid/${workspaceUUID}/administration/ranking`,
  )

  const request = axios
    .get<GetAdministrationRankingByWorkspaceUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetAddableEvaluatorsByTeamIdAndWorkspaceUUIDResponse =
  NullableContract<EvaluatorContract[]>

export async function getAddableEvaluatorsByTeamIdAndWorkspaceUUID(
  teamId: number,
  workspaceUUID: string,
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetAddableEvaluatorsByTeamIdAndWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/evaluations/by-workspace-uuid/${workspaceUUID}/teams/${teamId}/evaluators/linkable`,
  )

  const request = axios
    .get<GetAddableEvaluatorsByTeamIdAndWorkspaceUUIDResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetEvaluatableTeamsByWorkspaceUUIDResponse = NullableContract<
  PaginatedContract<TeamContract>
>

export async function getEvaluatableTeamsByWorkspaceUUID(
  workspaceUUID: string,
  requestParams: Record<string, string | number>,
  options: BaseRequestOptions = {},
): Promise<GetEvaluatableTeamsByWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/evaluations/by-workspace-uuid/${workspaceUUID}/teams/evaluatable`,
  )

  const request = axios
    .get<GetEvaluatableTeamsByWorkspaceUUIDResponse>(requestUri, {
      params: requestParams,
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetRankingByWorkspaceUUIDResponse = NullableContract<TeamContract[]>

export async function getRankingByWorkspaceUUID(
  workspaceUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetRankingByWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/evaluations/by-workspace-uuid/${workspaceUUID}/ranking`,
  )

  const request = axios
    .get<GetRankingByWorkspaceUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetEvaluationsSettingsByWorkspaceUUIDResponse =
  NullableContract<SettingsContract>

export async function getEvaluationsSettingsByWorkspaceUUID(
  workspaceUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetEvaluationsSettingsByWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/evaluations/by-workspace-uuid/${workspaceUUID}/settings`,
  )

  const request = axios
    .get<GetEvaluationsSettingsByWorkspaceUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}

export type GetTeamEvaluatorsByTeamIdAndWorkspaceUUIDResponse =
  NullableContract<EvaluatorContract[]>

export async function getTeamEvaluatorsByTeamIdAndWorkspaceUUID(
  teamId: number,
  workspaceUUID: string,
  options: BaseRequestOptions = {},
): Promise<GetTeamEvaluatorsByTeamIdAndWorkspaceUUIDResponse> {
  const { abortSignal } = options

  const requestUri = getApiUrl(
    `/evaluations/by-workspace-uuid/${workspaceUUID}/teams/${teamId}/evaluators`,
  )

  const request = axios
    .get<GetTeamEvaluatorsByTeamIdAndWorkspaceUUIDResponse>(requestUri, {
      signal: abortSignal,
    })
    .then((response) => response?.data ?? null)
    .catch(errorHandler(options))

  return request
}
